import {observer} from "mobx-react";
import {Col, Row} from "antd";
import {WorkNotice} from "@modules/Workbench/Components/WorkNotice";

export const Workbench = observer(() => {

  return <>
    <Row
      gutter={[16, 16]}
      className={'padding-top-xs padding-bottom-xs'}>
      { /*<Col span={24}>
        <AllApp></AllApp>
      </Col>*/ }

      <Col span={24} className={''}>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12}>
            <WorkNotice></WorkNotice>
          </Col>
        </Row>
      </Col>


    </Row>


  </>
})
