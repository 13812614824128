import React, {useEffect, useState} from "react";
import {Col, DatePicker, Form, Input, InputNumber, Row} from "antd";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import {FinalSettlementRow} from "@services/dto/finalSettlement";
import {SelectPersonCharge} from "@components/SelectPersonCharge";
import {SelectProject} from "@components/SelectProject";
import {SelectCooperativeUnit} from "@components/SelectCooperativeUnit";
import {allStores} from "@/stores";
import dayjs from "dayjs";
import {SelectFiling} from "@components/SelectFiling";
import {SelectDict} from "@components/SelectDict";
import {ProjectRow} from "@services/dto/project";
import {SelectBankAccount} from "@components/SelectBankAccount";
import {Any} from "@common/types";
import {BankAccountRow} from "@services/dto/bankAccount";
import {SelectFundApproval} from "@components/SelectFundApproval";
import {FORM_DISABLED_STATE} from "@/config";
import {SelectTaxPlan} from "@components/SelectTaxPlan";
import {TaxPlanRow} from "@services/dto/taxPlan";
import {SelectProcessStatus} from "@components/SelectProcessStatus";
import {ProjectService} from "@services/ProjectService";

export interface PropsType {
  row: FinalSettlementRow
  isView?: boolean
  isBatchUpdate?: boolean
}

export const BasicInfo = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const [form] = Form.useForm()
  const {filingStore} = allStores
  const [responsibilityProject, setResponsibilityProject] = useState<string>()
  const [cooperationUnit, setCooperationUnit] = useState<string | string[]>()

  useEffect(() => {
    form.resetFields()
    const finalSettlementRow = {...props.row}
    filingStore.setFilingFaCode(finalSettlementRow.fsCode)
    if (typeof finalSettlementRow.fsDate === 'string' && finalSettlementRow.fsDate !== '') {
      finalSettlementRow.fsDate = dayjs(finalSettlementRow.fsDate);
    }
    if (typeof finalSettlementRow.fundApprovalId === 'string' && finalSettlementRow.fundApprovalId !== '') {
      finalSettlementRow.fundApprovalId = finalSettlementRow.fundApprovalId.split(",")
    }
    if (typeof finalSettlementRow.cooperationUnit === 'string') {
      finalSettlementRow.cooperationUnit = finalSettlementRow.cooperationUnit !== '' ? finalSettlementRow.cooperationUnit.split(',') : []
    }
    setResponsibilityProject(finalSettlementRow.responsibilityProject);
    setCooperationUnit(finalSettlementRow.cooperationUnit)
    form.setFieldsValue(finalSettlementRow);
  }, [props.row.id])

  /**
   * @desc 责任部门修改 code拿到并赋值
   * @param value
   * @param options
   */
  const handleProjectChange = (value: any, options: ProjectRow[]) => {
    setResponsibilityProject(value)
    const row = options.find(o => value === o.name)
    form.setFieldsValue({code: row?.code})
  }

  /**
   * @desc 账户组件修改默认填充简称字段
   * @param value
   * @param options
   */
  const handleFaPaymentAccountChange = (value: Any | null, options: BankAccountRow[]) => {
    const row = options.find(o => value === o.id)
    form.setFieldsValue({fsAccountPay: row?.baNick || value})
    form.setFieldsValue({baName1: row?.baName || value})
    form.setFieldsValue({baAccount1: row?.baAccount || value})
    form.setFieldsValue({baBlank1: row?.baBlank || value})
  }

  /**
   * @desc 账户组件修改默认填充简称字段
   * @param value
   * @param options
   */
  const handleFaAccountsReceivableChange = (value: Any | null, options: BankAccountRow[]) => {
    const row = options.find(o => value === o.id)
    form.setFieldsValue({fsAccountIn: row?.baNick || value})
    form.setFieldsValue({baName2: row?.baName || value})
    form.setFieldsValue({baAccount2: row?.baAccount || value})
    form.setFieldsValue({baBlank2: row?.baBlank || value})
  }

  const handleTaxPlanChangeChange = async (value: Any | null, options: TaxPlanRow[]) => {
    const taxPlan = options.find(o => o.id === value)
    const cooperationUnit = typeof taxPlan?.cooperationUnit === 'string' ? taxPlan?.cooperationUnit?.split(",") : taxPlan?.cooperationUnit
    let responsibilityProject:ProjectRow
    if (taxPlan?.responsibilityProject) {
      [responsibilityProject] = await ProjectService.getBatchByName([taxPlan?.responsibilityProject])
    }
    setResponsibilityProject(taxPlan.responsibilityProject);
    setCooperationUnit(taxPlan?.cooperationUnit)
    form.setFieldsValue({
      employeeFeeTaxScheduleCode: taxPlan?.efaApplyCode,
      responsibilityProject: taxPlan?.responsibilityProject,
      cooperationUnit: cooperationUnit,
      code: responsibilityProject?.code
    })
  }

  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      labelCol={{span: 6}}
      wrapperCol={{span: 14}}
      disabled={props.isView || FORM_DISABLED_STATE.includes(props.row.fsStatus)}
      initialValues={{cooperationUnit: ['N/A']}}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Form.Item hidden name="id">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="code">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="fsAccountPay">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="fsAccountIn">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="personResponsibleName">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="organizersUserName">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="employeeFeeTaxScheduleCode">
          <Input/>
        </Form.Item>
        <Col span={24}>
          <Form.Item
            label="制单日期"
            name="fsDate"
            rules={[{required: false, message: '请输入制单日期!'}]}>
            <DatePicker disabled placeholder={"请输入制单日期"} className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="制单编号"
            name="fsCode"
            rules={[{required: false, message: '请输入制单编号!'}]}>
            <Input placeholder="请输入制单编号" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="税费类别"
            name="fsType"
            rules={[{required: false, message: '请输入税费类别!'}]}>
            <SelectDict code={'plan_accounting_payment_categories_type'} placeholder={'请选择税费类别'}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="税费摘要"
            name="fsDesc"
            rules={[{required: false, message: '请输入费用摘要!'}]}>
            <Input.TextArea placeholder="请输入费用摘要" autoSize={{minRows: 3, maxRows: 9}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="申请金额"
            name="fsAmount"
            rules={[{required: false, message: '请输入申请金额!'}]}>
            <InputNumber addonAfter={'元'} min={0} precision={2} placeholder="请输入申请金额" className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="原付款账户"
            name="fsAccountPay"
            rules={[{required: false, message: '请输入原付款账户!'}]}>
            <Input placeholder="请输入原付款账户"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="付款账户"
            name="fsAccountPayId"
            rules={[{required: false, message: '请输入付款账户!'}]}>
            <SelectBankAccount
              onChange={handleFaPaymentAccountChange}
              allowClear
              isShowAction
              bankAccountTypeOptions={[{id: '内部协作账户', value: '内部协作账户'}, {
                id: '外部协作账户',
                value: '外部协作账户'
              }]}
              placeholder={'请输入付款账户'}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="付款账户名称"
            name="baName1"
            rules={[{required: false, message: '付款账户请输入户名!'}]}>
            <Input placeholder="付款账户请输入户名" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="付款账户账号"
            name="baAccount1"
            rules={[{required: false, message: '请输入付款账户账号!'}]}>
            <Input placeholder="请输入付款账户账号" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="付款账户银行"
            name="baBlank1"
            rules={[{required: false, message: '请输入付款账户银行!'}]}>
            <Input placeholder="请输入付款账户银行" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="原收款账户"
            name="fsAccountIn"
            rules={[{required: false, message: '请输入原收款账户!'}]}>
            <Input placeholder="请输入原收款账户"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="收款账户"
            name="fsAccountInId"
            rules={[{required: false, message: '请输入收款账户!'}]}>
            <SelectBankAccount
              onChange={handleFaAccountsReceivableChange}
              allowClear
              isShowAction
              bankAccountTypeOptions={[{id: '内部协作账户', value: '内部协作账户'}, {
                id: '外部协作账户',
                value: '外部协作账户'
              }]}
              placeholder={'请输入收款账户'}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="收款账户名称"
            name="baName2"
            rules={[{required: false, message: '请输入收款账户名称!'}]}>
            <Input placeholder="请输入收款账户名称" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="收款账户账号"
            name="baAccount2"
            rules={[{required: false, message: '请输入收款账户账号!'}]}>
            <Input placeholder="请输入收款账户账号" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="收款账户银行"
            name="baBlank2"
            rules={[{required: false, message: '请输入收款账户银行!'}]}>
            <Input placeholder="请输入收款账户银行" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="关联资金审批"
            name="fundApprovalId"
            rules={[{required: false, message: '请选择关联资金审批!'}]}>
            <SelectFundApproval
              allowClear
              disabled
              placeholder={"请选择关联资金审批"}
              className={'width-100-percentage'}
              mode={"multiple"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="关联税费计划"
            name="employeeFeeTaxScheduleId"
            rules={[{required: !props.isBatchUpdate, message: '请选择关联税费计划!'}]}>
            <SelectTaxPlan
              placeholder={'请选择关联税费计划'}
              className={'width-100-percentage'}
              onChange={handleTaxPlanChangeChange}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="责任部门"
            name="responsibilityProject"
            rules={[{required: !props.isBatchUpdate, message: '请选择责任部门!'}]}>
            <SelectProject
              placeholder={"请选择责任部门"}
              onChange={handleProjectChange}
              allowClear
              fieldNames={{value: 'name', label: 'name'}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="协作单位"
            name="cooperationUnit"
            rules={[{required: false, message: '请输入协作单位!'}]}>
            <SelectCooperativeUnit
              disabled
              allowClear
              mode={'multiple'}
              fieldNames={{value: 'cuName', label: 'cuName'}}/>
          </Form.Item>
        </Col>
        {
          props.row.id && <Col span={24}>
            <Form.Item
              label="存档批号"
              name="fsFile"
              rules={[{required: false, message: '请选择存档批号!'}]}>
              <SelectFiling
                responsibilityProject={responsibilityProject}
                cooperationUnit={cooperationUnit}
                placeholder={"请选择存档批号"}
                inputDisabled
                isHideClose
                isHidePlus
                fieldNames={{value: "id", label: "faBatchNumber"}}
                allowClear/>
            </Form.Item>
          </Col>
        }
        { /* TODO 实现日期、实现金额、余额 三个字段不可输入，以后通过关联资金审批自动带出，表格列表也要进行展示这三个字段 */ }
        <Col span={24}>
          <Form.Item
            label="实现日期"
            name="taStatus"
            rules={[{required: false, message: '请输入实现日期!'}]}>
            <Input disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="实现金额"
            name="taStatus"
            rules={[{required: false, message: '请输入实现金额!'}]}>
            <InputNumber addonAfter={'元'} placeholder={'请输入实现金额'} disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="余额"
            name="taStatus"
            rules={[{required: false, message: '请输入余额!'}]}>
            <Input disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="办理人员"
            name="personResponsible"
            rules={[{required: false, message: '请选择办理人员!'}]}>
            <SelectPersonCharge
              disabled
              className={"width-100-percentage"}
              placeholder={"请选择办理人员"}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="主办人员"
            name="organizersUserId"
            rules={[{required: false, message: '请选择主办人员!'}]}>
            <SelectPersonCharge
              className={"width-100-percentage"}
              placeholder={"请选择主办人员"}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="办理进度"
            name="fsStatus"
            rules={[{required: false, message: '请输入办理进度!'}]}>
            <SelectProcessStatus
              allowClear
              placeholder={"请选择办理进度"}
              disabled/>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}))
