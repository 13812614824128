import React, {useEffect, useState} from "react";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import {DatePicker, Select} from "antd";
import {TaxAccountingReq} from "@services/dto/taxAccounting";
import dayjs from "dayjs";
import {SelectProject} from "@components/SelectProject";
import {SelectCooperativeUnit} from "@components/SelectCooperativeUnit";
import {CustomInputNumber} from "@components/CustomInputNumber";
import {SelectBankAccount} from "@components/SelectBankAccount";
import {SelectInvoiceRegister} from "@components/SelectInvoiceRegister";
import {SelectPersonCharge} from "@components/SelectPersonCharge";
import {SelectDict} from "@components/SelectDict";
import {SelectFundApproval} from "@components/SelectFundApproval";
import {CustomTableSort} from "@components/CustomTableSort";
import {logger} from "@common/utils";
import {Any} from "@common/types";
import {SelectFiling} from "@components/SelectFiling";
import {SelectTaxPlan} from "@components/SelectTaxPlan";
import {CustomAuthHeaderAction, PropsType as ParentPropsType} from "@components/CustomAuthHeaderAction";
import {SelectProcessStatus} from "@components/SelectProcessStatus";

export interface PropsType extends ParentPropsType {
  updateCount?: number, // 更新渲染标志
  refreshData?: (req: TaxAccountingReq) => void,
}

export const HeaderActionCom = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, _) => {

  const [searchReq, setSearchReq] = useState<TaxAccountingReq>({
    pageNum: 1,
    perPage: 10,
  })

  useEffect(() => {
    if (props.searchReq) {
      setSearchReq(props.searchReq);
    }
  }, [props.searchReq])

  const refreshData = (req: TaxAccountingReq) => {
    if (props.refreshData) {
      props.refreshData(req)
    }
  }

  const handleStatusCommonChange = (searchValue: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.statusCommon = searchValue
    setSearchReq(req)
    refreshData(req)
  }

  const handleSortChange = ([filed, sort]: string[]) => {
    logger.log(filed, sort)
    const req = {...searchReq}
    req.pageNum = 1
    req.sortField = `${filed ? filed : ''} ${sort ? sort : ''}`
    setSearchReq(req)
    refreshData(req)
  }

  const handleKeywordClear = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = undefined
    setSearchReq(req)
    refreshData(req)
  }

  const handleKeywordSearch = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleTaDateChange = (_: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null, [start, end]: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.taDate1 = start
    req.taDate2 = end
    setSearchReq(req)
    refreshData(req)
  }

  const handleFundingApprovalIdChange = (searchValue: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.fundApprovalId = searchValue
    setSearchReq(req)
    refreshData(req)
  }

  const handleTaSegmentChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.taSegment = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleTaEnterpriseChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.taEnterprise = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleTaCategoryChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.taCategory = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleTaxCategoryChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.taxCategory = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleTaxMethodChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.taxMethod = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleTaVatPayableChange = ([start, end]: string[]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.taVatPayable1 = start
    req.taVatPayable2 = end
    setSearchReq(req)
    refreshData(req)
  }

  const handlePersonDueChange = ([start, end]: string[]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.personDue1 = start
    req.personDue2 = end
    setSearchReq(req)
    refreshData(req)
  }

  const handleTaOutIrAmountChange = ([start, end]: string[]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.taOutIrAmount1 = start
    req.taOutIrAmount2 = end
    setSearchReq(req)
    refreshData(req)
  }

  const handleTaInIrAmountChange = ([start, end]: string[]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.taInIrAmount1 = start
    req.taInIrAmount2 = end
    setSearchReq(req)
    refreshData(req)
  }

  const handleTaOutTaxIrAmountChange = ([start, end]: string[]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.taOutTaxIrAmount1 = start
    req.taOutTaxIrAmount2 = end
    setSearchReq(req)
    refreshData(req)
  }

  const handleTaInTaxIrAmountChange = ([start, end]: string[]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.taInTaxIrAmount1 = start
    req.taInTaxIrAmount2 = end
    setSearchReq(req)
    refreshData(req)
  }

  const handleBsAccountPayIdChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.bsAccountPayId = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleBsAccountInIdChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.bsAccountInId = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleEmployeeFeeTaxScheduleIdChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.employeeFeeTaxScheduleId = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleInvoiceRegistrationOutIdChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.invoiceRegistrationOutId = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleInvoiceRegistrationInIdChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.invoiceRegistrationInId = value
    setSearchReq(req)
    refreshData(req)
  }

  const handlePersonResponsibleChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.personResponsible = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleResponsibilityProjectChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.responsibilityProject = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleCooperationUnitChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.cooperationUnit = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleFileArchiveChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.fileArchive = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleTaStatusChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.taStatus = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleOrganizersUserIdChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.organizersUserId = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleSearchReqReset = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.perPage = 10
    req.keyword = undefined
    req.cooperationUnit = undefined
    req.responsibilityProject = undefined

    req.taDate1 = undefined
    req.taDate2 = undefined
    req.taOutIrAmount1 = undefined
    req.taOutIrAmount2 = undefined
    req.taInIrAmount1 = undefined
    req.taInIrAmount2 = undefined
    req.taOutTaxIrAmount1 = undefined
    req.taOutTaxIrAmount2 = undefined
    req.taInTaxIrAmount1 = undefined
    req.taInTaxIrAmount2 = undefined
    req.advanceTaxPaid1 = undefined
    req.advanceTaxPaid2 = undefined
    req.taVatPayable1 = undefined
    req.taVatPayable2 = undefined
    req.additionalTaxAmount1 = undefined
    req.additionalTaxAmount2 = undefined
    req.taComprehensiveTaxBurden1 = undefined
    req.taComprehensiveTaxBurden2 = undefined
    req.taComprehensiveTaxRate1 = undefined
    req.taComprehensiveTaxRate2 = undefined
    req.personDue1 = undefined
    req.personDue2 = undefined
    req.bsAccountPay = undefined
    req.bsAccountPayId = undefined
    req.bsAccountIn = undefined
    req.bsAccountInId = undefined
    req.invoiceRegistrationOutId = undefined
    req.invoiceRegistrationInId = undefined
    req.taSegment = undefined
    req.taCategory = undefined
    req.taxCategory = undefined
    req.taxMethod = undefined
    req.employeeFeeTaxScheduleId = undefined
    req.employeeFeeTaxScheduleCode = undefined
    req.taEnterprise = undefined
    req.statusCommon = undefined
    req.sortField = undefined
    req.personResponsible = undefined
    req.personResponsibleName = undefined
    req.organizersUserId = undefined // 主办人员
    req.organizersUserName = undefined // 主办人员名
    req.taStatus = undefined
    req.fundApprovalId = undefined
    req.fileArchive = undefined

    setSearchReq(req)
    refreshData(req)
  }

  return (
    <>
      <CustomAuthHeaderAction
        columnWidth={20}
        columnLen={6}
        size={props.size}
        collapsed={props.collapsed}
        setCollapsed={props.setCollapsed}
        searchReq={props.searchReq}
        handleShowColumnReset={props.handleShowColumnReset}
        handleSearchReqReset={props.refreshData ? handleSearchReqReset : undefined}
        handleKeywordSearch={handleKeywordSearch}
        handleKeywordClear={handleKeywordClear}
        handleTableColumnsChange={props.handleTableColumnsChange}
        showColumn={props.showColumn}
        tableColumns={props.tableColumns}
        locationPathname={props.locationPathname}
        isShowKeyword={!props.refreshData}
        items={props.items}
        collapsedChildren={props.refreshData ? [
          <DatePicker.RangePicker
            placeholder={['制单开始日期', '制单结束日期']}
            onChange={handleTaDateChange}
            value={searchReq?.taDate1 ? [dayjs(searchReq?.taDate1), dayjs(searchReq?.taDate2)] : [undefined, undefined]}
            allowClear
            className={"width-100-percentage"}/>,
          <SelectProject
            placeholder={"请选择核算部门"}
            onChange={handleTaSegmentChange}
            value={searchReq?.taSegment}
            allowClear
            fieldNames={{value: 'name', label: 'name'}}/>,
          <SelectCooperativeUnit
            placeholder={"请选择核算企业"}
            value={searchReq?.taEnterprise}
            allowClear
            cooperativeUnitCategory={"内部协作单位"}
            onChange={handleTaEnterpriseChange}
            fieldNames={{value: 'cuName', label: 'cuName'}}/>,
          <Select
            onChange={handleTaCategoryChange}
            allowClear
            value={searchReq?.taCategory}
            options={[
              {label: '一般纳税人', value: '一般纳税人'},
              {label: '小规模纳税人', value: '小规模纳税人'},
            ]}
            placeholder={'请选择纳税人级别'}
            className={"width-100-percentage"}/>,
          <SelectDict
            onChange={handleTaxCategoryChange}
            className={'width-100-percentage'}
            value={searchReq?.taxCategory}
            allowClear
            code={'plan_accounting_payment_categories_type'}
            placeholder={'请选择税费类别'}/>,
          <Select
            onChange={handleTaxMethodChange}
            allowClear
            value={searchReq?.taxMethod}
            options={[
              {label: '一般计税法', value: '一般计税法'},
              {label: '简易计税法', value: '简易计税法'},
            ]}
            placeholder={'请选择计税方法'}
            className={"width-100-percentage"}/>,
          <SelectInvoiceRegister
            className={'width-100-percentage'}
            allowClear
            value={searchReq?.invoiceRegistrationOutId}
            isHiddenOption
            placeholder={'请选择关联销项发票'}
            onChange={handleInvoiceRegistrationOutIdChange}/>,
          <SelectInvoiceRegister
            className={'width-100-percentage'}
            allowClear
            value={searchReq?.invoiceRegistrationInId}
            isHiddenOption
            placeholder={'请选择关联进项发票'}
            onChange={handleInvoiceRegistrationInIdChange}/>,
          <CustomInputNumber
            placeholder={['销项价税合计', '销项价税合计']}
            value={[searchReq?.taOutIrAmount1, searchReq?.taOutIrAmount2]}
            className={'width-100-percentage'}
            onSearch={handleTaOutIrAmountChange}/>,
          <CustomInputNumber
            placeholder={['进项价税合计', '进项价税合计']}
            value={[searchReq?.taInIrAmount1, searchReq?.taInIrAmount2]}
            className={'width-100-percentage'}
            onSearch={handleTaInIrAmountChange}/>,
          <CustomInputNumber
            placeholder={['销项税额合计', '销项税额合计']}
            value={[searchReq?.taOutTaxIrAmount1, searchReq?.taOutTaxIrAmount2]}
            className={'width-100-percentage'}
            onSearch={handleTaOutTaxIrAmountChange}/>,
          <CustomInputNumber
            placeholder={['进项税额合计', '进项税额合计']}
            value={[searchReq?.taInTaxIrAmount1, searchReq?.taInTaxIrAmount2]}
            className={'width-100-percentage'}
            onSearch={handleTaInTaxIrAmountChange}/>,
          <CustomInputNumber
            placeholder={['应缴增值税额', '应缴增值税额']}
            value={[searchReq?.taVatPayable1, searchReq?.taVatPayable2]}
            className={'width-100-percentage'}
            onSearch={handleTaVatPayableChange}/>,
          <CustomInputNumber
            placeholder={['核算金额', '核算金额']}
            value={[searchReq?.personDue1, searchReq?.personDue2]}
            className={'width-100-percentage'}
            onSearch={handlePersonDueChange}/>,
          <SelectBankAccount
            className={'width-100-percentage'}
            allowClear
            value={searchReq?.bsAccountPayId}
            placeholder={'请选择付款账户'}
            onChange={handleBsAccountPayIdChange}/>,
          <SelectBankAccount
            className={'width-100-percentage'}
            allowClear
            value={searchReq?.bsAccountInId}
            placeholder={'请选择收款账户'}
            onChange={handleBsAccountInIdChange}/>,
          <SelectTaxPlan
            className={'width-100-percentage'}
            value={searchReq?.employeeFeeTaxScheduleId}
            onChange={handleEmployeeFeeTaxScheduleIdChange}
            placeholder={'请选择关联税费计划'}
            allowClear/>,
          <SelectFundApproval
            className={'width-100-percentage'}
            value={searchReq?.fundApprovalId}
            onChange={handleFundingApprovalIdChange}
            placeholder={'请选择关联资金审批'}
            allowClear/>,
          <SelectProject
            updateCount={props.updateCount}
            placeholder={"请选择责任部门"}
            value={searchReq?.responsibilityProject}
            onChange={handleResponsibilityProjectChange}
            allowClear
            fieldNames={{value: 'name', label: 'name'}}/>,
          <SelectCooperativeUnit
            onChange={handleCooperationUnitChange}
            value={searchReq?.cooperationUnit}
            allowClear
            fieldNames={{value: 'cuName', label: 'cuName'}}/>,
          <SelectFiling
            placeholder={"请选择存档批号"}
            allowClear
            isFaBatchNumberGroupBy
            fieldNames={{label: 'faBatchNumber', value: 'faBatchNumber'}}
            onChange={handleFileArchiveChange}
            value={searchReq?.fileArchive}
            className={'width-100-percentage'}
            isHiddenOptions/>,
          <SelectPersonCharge
            onChange={handlePersonResponsibleChange}
            className={"width-100-percentage"}
            placeholder={"请选择办理人员"}
            value={searchReq?.personResponsible}
            allowClear/>,
          <SelectPersonCharge
            onChange={handleOrganizersUserIdChange}
            className={"width-100-percentage"}
            placeholder={"请选择主办人员"}
            value={searchReq?.organizersUserId}
            allowClear/>,
          <SelectProcessStatus
            onChange={handleTaStatusChange}
            allowClear
            value={searchReq?.taStatus}
            placeholder={'请选择办理进度'}
            className={"width-100-percentage"}/>,
          <Select
            onChange={handleStatusCommonChange}
            allowClear
            value={searchReq?.statusCommon}
            options={[
              {label: '完成', value: '完成'},
              {label: '容缺', value: '容缺'},
            ]}
            placeholder={'请选择校验办理进度'}
            className={"width-100-percentage"}/>,
          <CustomTableSort
            value={searchReq?.sortField}
            onChange={handleSortChange}
            className={"width-100-percentage"}
            placeholder={['字段', '排序']}
            options={[
              {label: '创建时间', value: 'ta.created_at'},
              {label: '制单日期', value: 'ta.ta_date'},
            ]}
          />,
        ] : []}/>
    </>
  )
}))
