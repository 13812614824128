import React, {useEffect, useState} from "react";
import {Col, DatePicker, Form, Input, InputNumber, Row} from "antd";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import dayjs from "dayjs";
import {SelectDict} from "@components/SelectDict";
import {PayrollRow} from "@services/dto/Payroll";
import {SelectBankAccount} from "@components/SelectBankAccount";
import {BankAccountRow} from "@services/dto/bankAccount";
import {SelectPersonCharge} from "@components/SelectPersonCharge";
import {SelectProject} from "@components/SelectProject";
import {SelectCooperativeUnit} from "@components/SelectCooperativeUnit";
import {ProjectRow} from "@services/dto/project";
import {SelectFundApproval} from "@components/SelectFundApproval";
import {SelectFiling} from "@components/SelectFiling";
import {SelectEmolumentPlan} from "@components/SelectEmolumentPlan";
import {SelectProcessStatus} from "@components/SelectProcessStatus";

export interface PropsType {
  row: PayrollRow
  isView?: boolean
  isBatchUpdate?: boolean
}

export const BasicInfo = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const [form] = Form.useForm()
  const [responsibilityProject, setResponsibilityProject] = useState<string>()
  const [cooperationUnit, setCooperationUnit] = useState<string | string[]>()

  useEffect(() => {
    form.resetFields()
    const payrollRow = {...props.row}
    if (payrollRow.id) {
      if (typeof payrollRow.payrollDate === 'string' && payrollRow.payrollDate !== '') {
        payrollRow.payrollDate = dayjs(payrollRow.payrollDate);
      }
      if (typeof payrollRow.implementDate === 'string' && payrollRow.implementDate !== '') {
        payrollRow.implementDate = dayjs(payrollRow.implementDate);
      }
      if (typeof payrollRow.cooperationUnit === 'string') {
        payrollRow.cooperationUnit = payrollRow.cooperationUnit !== '' ? payrollRow.cooperationUnit.split(',') : []
      }
      setResponsibilityProject(payrollRow.responsibilityProject);
      setCooperationUnit(payrollRow.cooperationUnit)
    }
    form.setFieldsValue(payrollRow);
  }, [props.row.id])

  const handlePaymentAccountChange = (value: string, options: BankAccountRow[]) => {
    let option = options.find(item => item.id === value)
    form.setFieldsValue({paymentAccountNick: option?.baNick})
    form.setFieldsValue({paymentAccountTitle: option?.baName})
    form.setFieldsValue({paymentAccount: option?.baAccount})
    form.setFieldsValue({paymentAccountBank: option?.baBlank})
  }

  const handleCsEndInAccountChange = (value: string, options: BankAccountRow[]) => {
    let option = options.find(item => item.id === value)
    form.setFieldsValue({creditedAccountNick: option?.baNick})
    form.setFieldsValue({creditedAccountTitle: option?.baName})
    form.setFieldsValue({creditedAccount: option?.baAccount})
    form.setFieldsValue({creditedAccountBank: option?.baBlank})
  }

  const handleProjectChange = (value: any, options: ProjectRow[]) => {
    const row = options.find(o => value === o.name)
    setResponsibilityProject(value)
    form.setFieldsValue({code: row?.code})
  }

  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      labelCol={{span: 6}}
      initialValues={{
        cooperationUnit: ['N/A']
      }}
      wrapperCol={{span: 14}}
      disabled={props.isView}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Form.Item hidden name="id">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="code">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="employeeFeeCompensationPlanCode">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="personResponsibleName">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="paymentAccountNick">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="creditedAccountNick">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="organizersUserName">
          <Input/>
        </Form.Item>
        <Col span={24}>
          <Form.Item
            label="制单日期"
            name="payrollDate"
            rules={[{required: false, message: '请输入制单日期!'}]}>
            <DatePicker disabled placeholder={"请输入制单日期"} className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="制单编号"
            name="payrollNumber"
            rules={[{required: false, message: '请输入制单编号!'}]}>
            <Input placeholder="请输入制单编号" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="工资类别"
            name="payrollCategory"
            rules={[{required: false, message: '请选择工资类别!'}]}>
            <SelectDict allowClear code={"salary_category_type"} placeholder={"请选择工资类别"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="工资摘要"
            name="payrollDesc"
            rules={[{required: false, message: '请输入工资摘要!'}]}>
            <Input.TextArea placeholder="请输入工资摘要" autoSize={{minRows: 3, maxRows: 9}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="申请金额"
            name="payrollSalary"
            rules={[{required: false, message: '请输入申请金额!'}]}>
            <InputNumber addonAfter={'元'} precision={2} placeholder="请输入申请金额" className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="付款账户"
            name="paymentAccountId"
            rules={[{required: false, message: '请选择付款账户!'}]}>
            <SelectBankAccount
              className={'width-100-percentage'}
              allowClear
              isShowAction
              onChange={handlePaymentAccountChange}
              placeholder={'请选择付款账户'}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="付款账户名称"
            name="paymentAccountTitle"
            rules={[{required: false, message: '请输入付款账户名称!'}]}>
            <Input placeholder="请输入付款账户名称" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="付款银行账号"
            name="paymentAccount"
            rules={[{required: false, message: '请输入付款银行账号!'}]}>
            <Input placeholder="请输入付款银行账号" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="付款开户银行"
            name="paymentAccountBank"
            rules={[{required: false, message: '请输入付款开户银行!'}]}>
            <Input placeholder="请输入付款开户银行" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="收款账户"
            name="creditedAccountId"
            rules={[{required: false, message: '请选择收款账户!'}]}>
            <SelectBankAccount
              className={'width-100-percentage'}
              allowClear
              isShowAction
              onChange={handleCsEndInAccountChange}
              placeholder={'请选择收款账户'}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="收款账户名称"
            name="creditedAccountTitle"
            rules={[{required: false, message: '请输入收款账户名称!'}]}>
            <Input placeholder="请输入收款账户名称" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="收款银行账号"
            name="creditedAccount"
            rules={[{required: false, message: '请输入收款银行账号!'}]}>
            <Input placeholder="请输入收款银行账号" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="收款开户银行"
            name="creditedAccountBank"
            rules={[{required: false, message: '请输入收款开户银行!'}]}>
            <Input placeholder="请输入收款开户银行" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="关联薪资总表"
            name="payrollSummaryId"
            rules={[{required: false, message: '请选择薪资总表!'}]}>
            <SelectEmolumentPlan
              allowClear
              onChange={v => form.setFieldsValue({payrollSummaryCode: v})}
              placeholder={"请选择资金审批"}
              className={'width-100-percentage'}
            ></SelectEmolumentPlan>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="关联薪酬计划"
            name="employeeFeeCompensationPlanId"
            rules={[{required: false, message: '请选择薪酬计划!'}]}>
            <SelectEmolumentPlan
              placeholder={'请选择薪酬计划'} allowClear
              onChange={(v:string, options) => form.setFieldsValue({employeeFeeCompensationPlanCode: options.find(o => o.id === v).efaApplyCode})}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="关联资金审批"
            name="fundApprovalId"
            rules={[{required: false, message: '请选择资金审批!'}]}>
            <SelectFundApproval
              disabled
              isOnlyUnbound
              allowClear
              placeholder={"请选择资金审批"}
              className={'width-100-percentage'}
              isHiddenOption/>
          </Form.Item>
        </Col>
        {
          props.row.id && <Col span={24}>
            <Form.Item
              label="存档批号"
              name="payrollFile"
              rules={[{required: false, message: '请选择存档批号!'}]}>
              <SelectFiling
                responsibilityProject={responsibilityProject}
                cooperationUnit={cooperationUnit}
                placeholder={"请选择存档批号"}
                inputDisabled
                isHideClose
                isHidePlus
                fieldNames={{value: "id", label: "faBatchNumber"}}
                allowClear/>
            </Form.Item>
          </Col>
        }
        <Col span={24}>
          <Form.Item
            label="办理人员"
            name="personResponsible"
            rules={[{required: false, message: '请选择办理人员!'}]}>
            <SelectPersonCharge
              disabled
              className={"width-100-percentage"}
              placeholder={"请选择负责人"}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="主办人员"
            name="organizersUserId"
            rules={[{required: false, message: '请选择主办人员!'}]}>
            <SelectPersonCharge
              className={"width-100-percentage"}
              placeholder={"请选择主办人员"}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="责任部门"
            name="responsibilityProject"
            rules={[{required: !props.isBatchUpdate, message: '请选择责任部门!'}]}>
            <SelectProject
              placeholder={"请选择责任部门"}
              allowClear
              onChange={handleProjectChange}
              fieldNames={{value: 'name', label: 'name'}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="协作单位"
            name="cooperationUnit"
            rules={[{required: false, message: '请输入协作单位!'}]}>
            <SelectCooperativeUnit
              allowClear
              mode={'multiple'}
              disabled
              onChange={setCooperationUnit}
              fieldNames={{value: 'cuName', label: 'cuName'}}/>
          </Form.Item>
        </Col>
        { /* TODO 实现日期、实现金额、余额 三个字段不可输入，以后通过关联资金审批自动带出 */ }
        <Col span={24}>
          <Form.Item
            label="实现日期"
            name="implementDate"
            rules={[{required: false, message: '请输入实现日期!'}]}>
            <DatePicker disabled placeholder={"请输入实现日期"} className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="实现金额"
            name="implementMoney"
            rules={[{required: false, message: '请输入实现金额!'}]}>
            <InputNumber addonAfter={'元'} disabled precision={2} placeholder="请输入实现金额" className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="余额"
            name="balance"
            rules={[{required: false, message: '请输入余额!'}]}>
            <InputNumber disabled precision={2} placeholder="请输入余额" className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="办理进度"
            name="status"
            rules={[{required: false, message: '请输入办理进度!'}]}>
            <SelectProcessStatus
              allowClear
              placeholder={"请选择办理进度"}
              disabled/>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}))
