import React, {useEffect, useState} from "react";
import {Col, DatePicker, Form, Input, InputNumber, Row} from "antd";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import dayjs, {Dayjs} from "dayjs";
import {BankStatementRow} from "@services/dto/bankStatement";
import {SelectFiling} from "@components/SelectFiling";
import {SelectPersonCharge} from "@components/SelectPersonCharge";
import {SelectProject} from "@components/SelectProject";
import {SelectCooperativeUnit} from "@components/SelectCooperativeUnit";
import {SelectFundApproval} from "@components/SelectFundApproval";
import {SelectBankAccount} from "@components/SelectBankAccount";
import {ProjectRow} from "@services/dto/project";
import {allStores} from "@/stores";
import {Any} from "@common/types";
import {BankAccountRow} from "@services/dto/bankAccount";
import {FundApprovalReq, FundApprovalRow} from "@services/dto/fundApproval";
import {BLACK_LIST, DATE_SECONDS_FMT, FORM_DISABLED_STATE} from "@/config";
import {SelectProcessStatus} from "@components/SelectProcessStatus";
import {SelectApplicationFormType} from "@components/SelectApplicationFormType";

export interface PropsType {
  row: BankStatementRow
  isView?: boolean
  isBatchUpdate?: boolean
}

export const BasicInfo = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const [form] = Form.useForm()
  const {filingStore, fundApprovalStore} = allStores
  const [isCustom, setIsCustom] = useState<boolean>(false)
  const [planCode, setPlanCode] = useState<string[]>()
  const [faApplicationForm, setFaApplicationForm] = useState<string[]>()
  const [selectFundApprovalSearchReq, setSelectFundApprovalSearchReq] = useState<FundApprovalReq>({
    pageNum: 1,
    perPage: 10,
  })
  const [fundApprovalDetail, setFundApprovalDetail] = useState<FundApprovalRow>()

  useEffect(() => {
    form.resetFields()
    const bankStatementRow = {...props.row}
    filingStore.setFilingFaCode(bankStatementRow.code)
    if (typeof bankStatementRow.bsDate === 'string' && bankStatementRow.bsDate !== '') {
      setSelectFundApprovalSearchReq({...selectFundApprovalSearchReq, bsDate: bankStatementRow.bsDate})
      bankStatementRow.bsDate = dayjs(bankStatementRow.bsDate)
    }
    if (typeof bankStatementRow.bsDateM === 'string' && bankStatementRow.bsDateM !== '') {
      bankStatementRow.bsDateM = dayjs(bankStatementRow.bsDateM)
    }
    if (typeof bankStatementRow.fundingApprovalId === 'string') {
      bankStatementRow.fundingApprovalId = bankStatementRow.fundingApprovalId !== '' ? bankStatementRow.fundingApprovalId.split(',') : []
    }
    if (typeof bankStatementRow.responsibilityProject === 'string') {
      bankStatementRow.responsibilityProject = bankStatementRow.responsibilityProject !== '' ? bankStatementRow.responsibilityProject.split(',') : []
    }
    if (typeof bankStatementRow.cooperationUnit === 'string') {
      bankStatementRow.cooperationUnit = bankStatementRow.cooperationUnit !== '' ? bankStatementRow.cooperationUnit.split(',') : []
    }
    setPlanCode(bankStatementRow.planCode?.split(','))
    form.setFieldsValue(bankStatementRow);
  }, [props.row.id])

  useEffect(() => {
    if (fundApprovalStore.fundApprovalDetails.data?.data.length) {
      // 如果出纳账户为关联资金审批的付款账户，则转入金额，灰掉，不可编辑；
      // 如果出纳账户为关联资金审批的收款账户，则转出金额，灰掉，不可编辑；
      setFundApprovalDetail(fundApprovalStore.fundApprovalDetails.data?.data[0])
    }
  }, [fundApprovalStore.fundApprovalDetails])

  /**
   * @desc 责任部门修改 code拿到并赋值
   * @param valueArr
   * @param options
   */
  const handleProjectChange = (valueArr: string | string[], options: ProjectRow[]) => {
    if (typeof valueArr !== 'object') {
      return
    }
    const rows: ProjectRow[] = options.filter(o => valueArr.includes(o.name))
    form.setFieldsValue({code: rows.map(o => o.code).join(',')})
  }

  /**
   * @desc 账户组件修改默认填充简称字段
   * @param value
   * @param options
   */
  const handleBankAccountIdChange = (value: Any | null, options: BankAccountRow[]) => {
    const row = options.find(o => value === o.id)
    form.setFieldsValue({bsName: row?.baNick || value})
    form.setFieldsValue({baName1: row?.baName || value})
    form.setFieldsValue({baAccount1: row?.baAccount || value})
    form.setFieldsValue({baBlank1: row?.baBlank || value})
  }

  /**
   * @desc 账户组件修改默认填充简称字段
   * @param value
   * @param options
   */
  const handleBsOtherAccountIdChange = (value: Any | null, options: BankAccountRow[]) => {
    const row = options.find(o => value === o.id)
    form.setFieldsValue({bsOtherAccountName: row?.baNick || value})
    form.setFieldsValue({baName2: row?.baName || value})
    form.setFieldsValue({bsAccount: row?.baAccount || value})
    form.setFieldsValue({baBlank2: row?.baBlank || value})
  }

  const handleBsDateChange = (value: Dayjs | null) => {
    setSelectFundApprovalSearchReq({...selectFundApprovalSearchReq, bsDate: value?.format(DATE_SECONDS_FMT)})
  }

  /**
   * 关联资金审批时 自动填充
   * 1. 责任人id
   * 2. 责任人名字
   * 3. 责任部门
   * 4. 协作单位
   * 5. 存档批号
   * 这些任何时候都禁止填写
   * @param valueArr
   * @param options
   */
  const handleFundingApprovalIdChange = (valueArr: string[] | string, options: FundApprovalRow[]) => {
    if (typeof valueArr === 'object') {
      if (!BLACK_LIST.every(v => !valueArr.includes(v))) {
        form.setFieldsValue({
          fundingApprovalId: [],
          fundingApprovalCode: [],
          responsibilityProject: [],
          cooperationUnit: [],
          bsOtherAccountId: undefined,
          bsOtherAccountName: undefined,
          baName2: undefined,
          bsAccount: undefined,
          baBlank2: undefined,
        })
        return
      }
      const rows = options.filter(item => valueArr.includes(item.id))
      form.setFieldsValue({
        fundingApprovalCode: handleParameterConversion(rows, 'faCode'),
        responsibilityProject: handleParameterConversion(rows, 'responsibilityProject'),
        cooperationUnit: handleParameterConversion(rows, 'cooperationUnit'),
        bsOtherAccountId: props.row.bsOutAmount ? rows[0]?.faAccountsReceivableId : rows[0]?.faPaymentAccountId,
        bsOtherAccountName: props.row.bsOutAmount ? rows[0]?.faAccountsReceivable : rows[0]?.faPaymentAccount,
        baName2: props.row.bsOutAmount ? rows[0]?.baName2 : rows[0]?.baName1,
        bsAccount: props.row.bsOutAmount ? rows[0]?.baAccount2 : rows[0]?.baAccount1,
        baBlank2: props.row.bsOutAmount ? rows[0]?.baBlank2 : rows[0]?.baBlank1,
      })
    }
  }

  const handleFundingApprovalIdInitChange = (valueArr: string[] | string, options: FundApprovalRow[]) => {
    if (typeof valueArr === 'object') {
      if (!BLACK_LIST.every(v => !valueArr.includes(v))) {
        form.setFieldValue("faApplicationFormType", undefined);
        form.setFieldValue("planType", undefined);
        return
      }
      const rows = options.filter(item => valueArr.includes(item.id))
      form.setFieldValue("faApplicationFormType", rows[0]?.faApplicationFormType);
      setFaApplicationForm(handleParameterConversion(rows, 'faApplicationForm'))
    }
  }

  /**
   * 数据转换
   * @param rows
   * @param key
   */
  const handleParameterConversion = (rows: FundApprovalRow[], key: string): string[] => {
    return rows?.map(o => o[key])
      .join(',')
      .split(',')
      .filter((v, i, self) => self.indexOf(v) === i)
      .filter(o => !BLACK_LIST.includes(o)) || []
  }

  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      labelCol={{span: 6}}
      wrapperCol={{span: 16}}
      disabled={props.isView || FORM_DISABLED_STATE.includes(props.row.bsStatus)}
      initialValues={{
        code: undefined,
        cooperationUnit: ['N/A']
      }}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Form.Item hidden name="id">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="code">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="bsName">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="bsOtherAccountName">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="personResponsibleName">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="fundingApprovalCode">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="organizersUserName">
          <Input/>
        </Form.Item>
        {
          props.row?.id && <Col span={24}>
            <Form.Item
              label="排序"
              name="sort"
              rules={[{required: false, message: '请输入排序!'}]}>
              <InputNumber precision={0} className={'width-100-percentage'} placeholder="请输入排序"/>
            </Form.Item>
          </Col>
        }
        <Col span={24}>
          <Form.Item
            label="制单日期"
            name="bsDateM"
            rules={[{required: false, message: '请输入制单日期!'}]}>
            <DatePicker
              onChange={handleBsDateChange}
              // showTime
              placeholder={"请输入制单日期"}
              className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="制单编号"
            name="bsCode"
            rules={[{required: false, message: '请输入制单编号!'}]}>
            <Input placeholder="请输入制单编号" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="实现日期"
            name="bsDate"
            rules={[{required: false, message: '请输入实现日期!'}]}>
            <DatePicker
              onChange={handleBsDateChange}
              // showTime
              placeholder={"请输入实现日期"}
              className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="款项摘要"
            name="bsSummary"
            rules={[{required: false, message: '请输入款项摘要!'}]}>
            <Input.TextArea
              placeholder="请输入款项摘要"
              disabled
              autoSize={{minRows: 3, maxRows: 9}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="出纳账户"
            name="bankAccountId"
            rules={[{required: false, message: '请选择出纳账户!'}]}>
            <SelectBankAccount
              isEnableFocusEvent
              searchReq={{
                pageNum: 1,
                perPage: 10,
                baTypes: '内部协作账户,员工公务账户,员工工资账户',
              }}
              allowClear
              disabled
              onChange={handleBankAccountIdChange}
              fieldNames={{value: 'id', label: 'baNick'}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="出纳账户名称"
            name="baName1"
            rules={[{required: false, message: '请输入出纳账户名称!'}]}>
            <Input placeholder="请输入出纳账户名称" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="出纳账户账号"
            name="baAccount1"
            rules={[{required: false, message: '请输入出纳账户账号!'}]}>
            <Input placeholder="请输入出纳账户账号" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="出纳账户银行"
            name="baBlank1"
            rules={[{required: false, message: '请输入出纳账户银行!'}]}>
            <Input placeholder="请输入出纳账户银行" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="出纳账户类型"
            name="baType1"
            rules={[{required: false, message: '请输入出纳账户类型!'}]}>
            <Input placeholder="请输入出纳账户类型" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="转入金额"
            name="bsInAmount"
            rules={[{required: false, message: '请输入转入金额!'}]}>
            <InputNumber
              precision={2}
              addonAfter={'元'}
              disabled={props.row.bankAccountId === fundApprovalDetail?.faPaymentAccountId || props.isView}
              placeholder="请输入转入金额"
              className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="转出金额"
            name="bsOutAmount"
            rules={[{required: false, message: '请输入转出金额!'}]}>
            <InputNumber
              precision={2}
              addonAfter={'元'}
              disabled={props.row.bankAccountId === fundApprovalDetail?.faAccountsReceivableId || props.isView}
              placeholder="请输入转出金额"
              className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="余额"
            name="bsAmount"
            rules={[{required: false, message: '请输入余额!'}]}>
            <InputNumber
              min={0}
              disabled
              precision={2}
              placeholder="请输入余额"
              className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="对方账户"
            name="bsOtherAccountId"
            rules={[{required: false, message: '请选择对方账户!'}]}>
            <SelectBankAccount
              isEnableFocusEvent
              allowClear
              isShowAction
              disabled={!props.row.baName2.includes("个人工资账户") || props.isView}
              bankAccountTypeOptions={[{id: '员工工资账户', value: '员工工资账户'}]}
              onChange={handleBsOtherAccountIdChange}
              placeholder={'请选择对方账户'}
              fieldNames={{value: 'id', label: 'baNick'}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="对方账户名称"
            name="baName2"
            rules={[{required: false, message: '请输入对方账户名称!'}]}>
            <Input placeholder="请输入对方账户名称" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="对方账户账号"
            name="bsAccount"
            rules={[{required: false, message: '请输入对方账户账号!'}]}>
            <Input placeholder="请输入对方账户账号" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="对方账户银行"
            name="baBlank2"
            rules={[{required: false, message: '请输入对方账户银行!'}]}>
            <Input placeholder="请输入对方账户银行" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="关联资金审批"
            name="fundingApprovalId"
            rules={[{required: false, message: '请选择关联资金审批!'}]}>
            <SelectFundApproval
              allowClear
              className={'width-100-percentage'}
              isHiddenOption
              mode={'multiple'}
              isCustom={isCustom}
              onChange={handleFundingApprovalIdChange}
              onInitChange={handleFundingApprovalIdInitChange}
              handleIsCustomChange={value => setIsCustom(value)}
              searchReq={selectFundApprovalSearchReq}></SelectFundApproval>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="关联申请表单"
            name="faApplicationFormType"
            rules={[{required: false, message: '请选择关联申请表单!'}]}>
            <SelectApplicationFormType
              className={'width-100-percentage'}
              disabled
              allowClear
              isOption
              showKey={['工资发放', '员工借款', '费用报销', '合同结算', '税费核算', '税费缴纳']}
              isOnlyUnBindFa={[/*'工资发放',*/ /*'员工借款',*/ /*'费用报销',*/ /*'合同结算',*/ /*'税费核算',*/ /*'税费缴纳',*/ /*'资金出纳'*/]}
              mode={'multiple'}
              placeholder={'请选择关联申请表单'}
              tableValue={faApplicationForm}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="关联计划表单"
            name="planType"
            rules={[{required: false, message: '请选择关联计划表单!'}]}>
            <SelectApplicationFormType
              className={'width-100-percentage'}
              disabled
              allowClear
              isOption
              mode={'multiple'}
              placeholder={'请选择关联计划表单'}
              tableValue={planCode}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="责任部门"
            name="responsibilityProject"
            rules={[{required: !props.isBatchUpdate, message: '请选择责任部门!'}]}>
            <SelectProject
              mode={'multiple'}
              placeholder={"请选择责任部门"}
              onChange={handleProjectChange}
              allowClear
              disabled
              fieldNames={{value: 'name', label: 'name'}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="协作单位"
            name="cooperationUnit"
            rules={[{required: false, message: '请输入协作单位!'}]}>
            <SelectCooperativeUnit
              disabled
              mode={'multiple'}
              allowClear
              fieldNames={{value: 'cuName', label: 'cuName'}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="存档批号"
            name="bsFile"
            rules={[{required: false, message: '请选择存档批号!'}]}>
            <SelectFiling
              isHidePlus
              isHideClose
              placeholder={"请选择存档批号"}
              inputDisabled
              fieldNames={{value: "id", label: "faBatchNumber"}}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="办理人员"
            name="personResponsible"
            rules={[{required: false, message: '请选择办理人员!'}]}>
            <SelectPersonCharge
              allowClear
              disabled
              className={"width-100-percentage"}
              placeholder={"请选择办理人员"}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="主办人员"
            name="organizersUserId"
            rules={[{required: false, message: '请选择主办人员!'}]}>
            <SelectPersonCharge
              className={"width-100-percentage"}
              placeholder={"请选择主办人员"}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="办理进度"
            name="bsStatus"
            rules={[{required: false, message: '请输入办理进度!'}]}>
            <SelectProcessStatus
              allowClear
              placeholder={"请选择办理进度"}
              disabled/>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}))
